@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    cursor: pointer;
    border-radius: 8px;
    margin-top: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    cursor: pointer;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
    cursor: pointer;
    border-radius: 8px;
  }

  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

.ant-form-item-has-error {
  .ant-select.saturn-select {
    &:hover,
    &:focus {
      .ant-select-selector {
        border-color: $error-color !important;
      }
    }

    .ant-select-selector {
      border-color: $error-color !important;
    }
  }
}

.ant-select.saturn-select.autocomplete
  .ant-select-selector
  .ant-select-selection-search
  .ant-select-selection-search-input {
  caret-color: $black !important;
}

.ant-select.saturn-select,
.ant-select.saturn-select-basic {
  display: block;
  width: 100%;
  font-family: $font-family-base;
  height: auto;

  .ant-select-single {
    line-height: 55px;
  }

  &.ant-select-multiple {
    .ant-select-selector {
      @media screen and (width >= 320px) and (width <= 480px) {
        min-height: 55px;
        height: auto;
      }
    }

    .ant-select-selection-overflow {
      flex-wrap: nowrap;
      overflow-y: hidden;
      cursor: pointer;

      @include scrollbars(5px, rgba(0, 0, 0, 0.5), $white);

      padding-bottom: 5px;
      margin-top: 15px;

      @media screen and (width >= 320px) and (width <= 480px) {
        flex-wrap: wrap;
        padding-top: 4px;
        margin-top: 0;
      }
    }
  }

  &.ant-select-auto-complete {
    .ant-select-selection-search-input {
      padding-top: 15px;
    }
  }

  .ant-select-selector {
    border: 1px solid $gray20;
    border-radius: 4px;
    height: 55px;
    font-size: 16px;
    font-weight: bold;
    padding: 0 20px 0 12px;
    position: relative;

    @media screen and (width >= 320px) and (width <= 768px) {
      // if the input font size is less than 16px, IOS behaves weirdly and zooms in when the users focuses on it, which leads to weird behavior
      // https://defensivecss.dev/tip/input-zoom-safari/
      // fixing issue https://support.dataart.com/browse/AADTT-14932
      input {
        font-size: 16px;
      }
    }

    .ant-select-selection-search {
      margin-inline-start: 0;
      font-family: $font-family-base;

      .ant-select-selection-search-input {
        height: 100%;
        caret-color: transparent;
      }
    }
  }

  &.ant-select-open {
    .ant-select-selector {
      .ant-select-selection-search {
        .ant-select-selection-search-input {
          caret-color: var(--black);
        }
      }
    }
  }

  .ant-select-selection-item {
    position: static;
    align-self: start;
  }

  .ant-select-selection-placeholder {
    align-self: start;
  }

  .float-label {
    position: relative;

    .content {
      color: $gray90;
      padding-top: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 700;
      height: 53px;
      box-sizing: border-box;
    }

    .label {
      color: $gray40;
      font-size: 15px;
      font-weight: normal;
      pointer-events: none;
      transform: translateY(-41px);
      overflow: hidden;
      text-overflow: ellipsis;

      @include transition(color 0.2s, left 0.2s, top 0.2s);

      &.label-float {
        transform: translateY(-53px);
        font-size: 12px;
      }
    }

    label {
      font-size: inherit;
      position: static;
      padding-left: 0;
    }
  }

  .loading-indicator {
    .saturn-icon {
      transform-origin: center;
      right: 6px;
      animation-name: spin;
      animation-duration: 1500ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }
  }

  .saturn-icon {
    position: absolute;
    right: 0;
    top: 16px;
    transform-origin: 10px 10px;

    @include transition(transform 0.2s);

    transform: rotate(180deg);
    background-color: transparent;

    svg {
      path {
        fill: $gray40;

        @include transition(fill 0.2s);
      }
    }
  }

  &:hover,
  &:focus,
  &.ant-select-focused {
    .ant-select-selector {
      border-color: $primary-color !important;
      box-shadow: none !important;
    }

    .float-label {
      .label {
        color: $primary-color;
      }
    }

    .saturn-icon {
      svg path {
        fill: $primary-color;
      }
    }
  }

  &.ant-select-focused {
    .saturn-icon {
      transform: rotate(180deg);
    }
  }

  &.ant-select-open {
    &.ant-select-show-search {
      .ant-select-selection-placeholder {
        .float-label .label {
          opacity: 0;
          transition: all ease-out 0.2s;
        }
      }

      .ant-select-selection-item {
        .float-label .label {
          opacity: 0;
          transition: all ease-out 0.2s;
        }

        .float-label .content {
          opacity: 0;
          transition: all ease-out 0.2s;
        }
      }
    }

    .saturn-icon {
      transform: rotate(0);
    }
  }

  &.ant-select-disabled:hover {
    .ant-select-selector {
      border-color: $gray20 !important;

      .float-label .label {
        color: $gray40;
      }

      svg path {
        fill: $gray40;
      }
    }
  }
}

.ant-select.ant-select-disabled {
  .saturn-icon {
    display: none;
  }
}

.ant-select.saturn-select-basic {
  .ant-select-selection-item {
    display: flex;
    align-self: center;
    position: static;
  }

  .float-label {
    .content {
      color: $gray90;
      font-weight: 700;
      padding-top: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      height: auto;
    }
  }
}

.ant-select-dropdown {
  z-index: 21;
  border-radius: 2px;

  .ant-select-item {
    min-height: 34px;
    padding: 5px 15px;
  }

  .ant-select-item-option {
    &:hover,
    &.ant-select-item-option-selected {
      background-color: $gray10;
      font-weight: normal;
    }
  }
}

.saturn-tags {
  .ant-select.saturn-select.ant-select-multiple {
    .ant-select-selection-overflow {
      margin-top: 0;
    }
  }
}

.ant-select-multiple {
  &.saturn-select .float-label .label {
    top: 0;
  }
}

.saturn-multiselect-dropdown {
  .ant-select-item-option {
    .saturn-icon {
      margin-right: 5px;
    }

    .saturn-icon-selected {
      display: none;
    }

    .saturn-icon-not-selected {
      display: inline-block;
    }
  }

  .ant-select-item-option.ant-select-item-option-selected {
    .saturn-icon-selected {
      display: inline-block;
    }

    .saturn-icon-not-selected {
      display: none;
    }
  }
}

.saturn-select-wrapper {
  position: relative;

  .saturn-select-multi-label {
    color: $gray40;
    font-size: 15px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 10px;
    top: 14px;
    z-index: 10;

    &.label-float {
      top: 0;
      font-size: 12px;
    }
  }
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  padding-right: 5px;
}
