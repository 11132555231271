.wrapper {
  :global {
    .ant-modal-header {
      padding-right: 40px;

      .ant-modal-title {
        font-weight: 600;
      }
    }

    .ant-modal-body {
      .ant-form-item {
        label {
          font-weight: 600;
        }
      }
    }
  }
}
