@import '../../../../styles/variables';

.content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  padding: 30px 0;
}

.togglersContnet {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  padding: 30px 0;
}

.toggleWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  height: 64px;

  label {
    margin-bottom: 20px;
  }
}

.textAreaWrapper {
  display: grid;
  grid-template-columns: auto 47px;
  align-items: center;
}

.promocodesSelect {
  flex: 1;
}

.footer {
  position: absolute;
  display: flex;
  gap: 16px;

  .extraPickerBtn {
    padding: 0;

    &:hover {
      background: transparent !important;
      opacity: 0.8;
    }

    span {
      color: $primary-color;
      font-size: 14px;
    }

    &.alignLeft {
      left: 8px;
    }
  }
}

.displayNowWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  span {
    font-weight: 600;
  }
}

.dateTimeBlockItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin-bottom: 20px;
}

.datePicker {
  margin-bottom: 0;
  flex: 1;
}
