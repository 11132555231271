$sides: (
  '': '',
  'top': 'top',
  'bottom': 'bottom',
  'left': 'left',
  'right': 'right',
);
$breakpoints: (
  '': '',
  'xs': 576px,
  'sm': 768px,
  'md': 992px,
  'lg': 1200px,
  'xxl': 1600px,
);

@each $breakName, $breakValue in $breakpoints {
  @each $sideName, $sideValue in $sides {
    @for $i from 0 through 120 {
      $property: if($sideName == '', '', -#{$sideValue});
      $space: $i * 10;
      $selector: '';

      @if $breakName != '' {
        $selector: #{$sideName}-#{$breakName}-#{$i};
      } @else {
        $selector: #{$sideName}-#{$i};
      }

      @if $breakName != '' {
        @media (min-width: $breakValue) {
          .margin#{$selector} {
            margin#{$property}: #{$space}px;
          }

          .padding#{$selector} {
            padding#{$property}: #{$space}px;
          }
        }
      } @else {
        .margin#{$selector} {
          margin#{$property}: #{$space}px;
        }

        .padding#{$selector} {
          padding#{$property}: #{$space}px;
        }
      }
    }
  }
}
