@import '../breakpoints/breakpoints';
@import '../base/variables';

.saturn-next-img {
  img {
    pointer-events: none;
  }
}

.wrapper {
  min-height: 500px;
  color: $white;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (width <= 756px) {
    grid-template-columns: auto;
    height: 100%;
  }

  .image-holder {
    display: block;

    .saturn-next-img {
      height: 100%;
      min-height: 500px;

      & > span {
        width: 100% !important;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    @media screen and (width <= 768px) and (width >= 320px) {
      display: none;
    }
  }

  .desc-holder {
    background: $gradient-reversed;
    display: flex;
    align-items: center;
  }

  .desc {
    padding: 50px 50px 20px;

    @media screen and (width <= 768px) and (width >= 320px) {
      padding: 20px;
    }

    @include mq(xl) {
      width: 710px;
      padding: 30px 105px;
    }
  }

  .buttons-holder {
    display: flex;
    flex-wrap: wrap;
  }

  .ant-btn {
    background-color: $white;
    color: $gray90;
    margin: 0 0 20px;
    font-weight: 700;
    border-color: $white;
    width: 100%;
    padding: 0;

    @include mq(sm) {
      margin: 0 20px 20px 0;
      max-width: 230px;
      width: 100%;
      overflow: hidden;

      span {
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
      }
    }

    @media screen and (width >= 320px) and (width <= 576px) {
      span {
        white-space: pre-wrap;
      }
    }

    &:hover {
      color: $white;
      background-color: $royal-blue-dark;
      border-color: $royal-blue-dark;
    }
  }

  .link-back {
    font-size: 11px;
    font-weight: 700;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    button:hover,
    button:focus {
      &,
      svg {
        outline: none;
        text-decoration: none;
        color: $royal-blue-dark;
        fill: $royal-blue-dark;
      }
    }

    .saturn-icon {
      margin-right: 4px;
    }

    svg {
      fill: $white;
    }

    a {
      text-transform: uppercase;
      text-decoration: underline;
      display: flex;
      align-items: center;
    }
  }

  h2 + p {
    margin: 0 0 52px;
  }

  p {
    margin: 0 0 28px;

    span {
      color: $disabled-blue;
    }

    .stepper-question {
      font-size: 1.125rem;
      margin-left: 5px;
    }
  }

  a {
    color: $white;
  }
}
