@import '../../../../../../../saturn-uikit/src/styles/base/variables';
@import '../../../../../../../saturn-uikit/src/styles/base/transitions';

.wrapper {
  margin-bottom: 30px;

  &.horizontal {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    margin-bottom: 10px;

    .header {
      order: 1;
      background-color: transparent;
      margin-top: -9px;

      & > span {
        display: none;
      }

      .deleteBlockButton {
        button {
          height: 40px;
        }
      }

      &.withoutDeleteButton {
        display: none;
      }
    }
  }

  .header {
    background-color: $gray10;
    margin-bottom: 10px;
    padding: 10px 20px;
    display: flex;
    align-items: center;

    > span {
      color: $gray90;
      font-size: 18px;
      margin-right: 21px;
    }

    .deleteBlockButton {
      position: relative;

      :global .ant-btn {
        border: none;
        color: $red;
        height: 30px;
        font-size: 11px;
        font-weight: bold;
        padding: 13px 16px;
        text-transform: uppercase;

        svg {
          fill: $red;
          font-size: 15px;
          margin-right: -3px;

          @include transition(fill 0.3s);
        }

        &:hover,
        &:active,
        &:focus {
          background-color: $red;
          color: $white;

          svg {
            fill: $white;
          }
        }
      }
    }

    .changePosition {
      margin-left: auto;

      .changePositionButton {
        text-decoration: underline;
      }

      .changePositionDropdown {
        display: flex;
        align-items: center;
        gap: 10px;

        .moveToLabel {
          flex: 1 0 auto;
          font-weight: 700;
        }

        .selectWrapper {
          flex: 1 0 110px;
        }
      }
    }
  }

  .content {
    @media (width >= 768px) {
      display: flex;
      margin-bottom: 35px;
    }

    :global {
      .saturn-image-uploader {
        margin-right: 20px;
        margin-bottom: 20px;

        + div {
          display: flex;
          justify-content: space-between;
          flex: auto;
          flex-direction: column;

          .float-label {
            margin-bottom: 20px;
            margin-right: 0;

            @media (width >= 768px) {
              margin-bottom: 0;
            }
          }
        }

        @media (width >= 768px) {
          margin-bottom: 0;
        }
      }
    }
  }
}
