.content {
  padding: 30px 0;
}

.inputsWrapper {
  display: grid;
  grid-gap: 20px;
  grid-template-rows: minmax(75px, auto);

  > div {
    margin: 0;
  }
}
