@import '../../../../../saturn-uikit/src/styles/base/variables';
@import '../../../../../saturn-uikit/src/styles/base/transitions';

.wrapper {
  .content {
    :global {
      .saturn-image-uploader {
        margin-right: 20px;
        margin-bottom: 20px;

        + div {
          display: flex;
          justify-content: space-between;
          flex: auto;
          flex-direction: column;

          .float-label {
            margin-bottom: 20px;
            margin-right: 0;
          }
        }
      }
    }
  }

  .controlPanel {
    button {
      background-color: $lavender;
      border-color: $primary-color;
      color: $primary-color;
      font-size: 11px;
      font-weight: bold;
      height: 40px;
      padding: 13px 39px;
      text-shadow: none;
      text-transform: uppercase;

      svg {
        fill: $primary-color;
        font-size: 16px;
        margin-right: -3px;

        @include transition(fill 0.3s);
      }

      :global {
        .ant-btn-primary {
          background-color: $lavender;
          border-color: $primary-color;
          color: $white;
        }
      }

      &:focus {
        color: $white;

        svg {
          fill: $white;
        }
      }

      &:hover,
      &:active {
        background-color: $royal-blue-dark;
        border-color: $royal-blue-dark;

        svg {
          fill: $white;
        }
      }
    }
  }
}
