/* THEME COLORS */
$primary-color: #006fcf;
$secondary-color: #409d66;

/* THEME COLORS */

/* BASIC COLORS */
$white: #fff;
$gray5: #c8c9c7;
$gray10: #f7f8f9;
$gray15: #ecedee;
$gray20: #dadee2;
$gray30: #616161;
$gray40: #97999b;
$gray50: #737679;
$gray60: #fafafa;
$gray70: #9e9e9e;
$gray80: #616161;
$gray84: #d6d6d6;
$gray90: #333;
$midnight-black: #252525;
$munsell: #f2f3f4;

/* PRIMARY COLORS */
$bright-navy-blue: #006fcf;
$disabled-blue: #99c5ec;
$light-blue: #ecf6fd;
$royal-blue-dark: #00175a;
$alice-blue: #edf1f4;
$lavender: #e2ebf3;
$black: #000;
$black-pearl: #001c34;
$deep-saphire-blue: #0b1942;
$deep-azure: #004179;
$dark-blue-gray: #66749c;
$iron: #ccd2d6;
$color_1: #42444a;

/* STATUS COLORS */
$shamrock-green: #409d66;
$tangerine: #f48809;
$red: #b42c01;

/* TYPOGRAPHY */
$font-size-body-big: 1.25rem;
$font-size-body-large: 1.125rem;
$font-size-body-base: 1rem;
$font-size-body-light: 0.9375rem;
$font-size-body-small: 0.875rem;
$font-size-input-description: 0.75rem;
$line-height-body-base: 1.5rem;
$font-family-base: 'Helvetica', arial, sans-serif;
$font-family-heading-base: 'BentonSans', sans-serif;
$font-weight-base: 400;
$font-size-collapse-title: 1.25rem;

/* BUTTONS */
$btn-disabled-opacity: 1;
$btn-padding-x: 45px;
$btn-padding-y: 13px;
$btn-height: 50px;

/* GRIDS */
$container-max-width: 1240px default;
$container-fluid-lg: 1600px default;

/* FORMS */
$input-bg: $white;
$input-border-color: $gray20;
$input-height: 55px;
$input-disabled-bg: $gray10;
$input-border-focused: $primary-color;
$error-color: $red;
$switcher-color-checked: $secondary-color;

/* VISUAL FEATURES */
$gradient: linear-gradient(236.84deg, $primary-color 23.67%, $royal-blue-dark 151.99%);
$gradient-reversed: linear-gradient(-236.84deg, $primary-color 23.67%, $royal-blue-dark 151.99%);
$box-shadow: 4px 4px 50px transparentize($deep-azure, 0.9);
$box-shadow-alt: 5px 5px 50px transparentize($iron, 0.2);
$box-shadow-alt-light: 4px 4px 40px rgb(0 65 121 / 7%);
$border-radius-base: 4px;
$min-collapsible-panel-height: 60px;
$animation-base: 0.3s ease-in color;
