@import 'variables';

body {
  font-weight: $font-weight-base;
  font-size: $font-size-body-base;
  line-height: $line-height-body-base;
  font-family: $font-family-base;
}

p {
  margin-bottom: 20px;
}

%heading {
  font-family: $font-family-heading-base;
  line-height: normal;
}

$headings: (
  1: (
    size: 2.375rem,
    line-height: 2.75rem,
    offset: 1em,
    weight: 300,
  ),
  2: (
    size: 1.875rem,
    line-height: 2.375rem,
    offset: 1em,
    weight: 300,
  ),
  3: (
    size: 1.25rem,
    line-height: 1.75rem,
    offset: 1em,
    weight: 400,
  ),
  4: (
    size: 1rem,
    line-height: 1.5rem,
    offset: 1em,
    weight: 600,
  ),
  5: (
    size: 0.9375rem,
    line-height: 1.375rem,
    offset: 1em,
    weight: 600,
  ),
  6: (
    size: 0.8125rem,
    line-height: 1.125rem,
    offset: 1rem,
    weight: 600,
  ),
);

@each $heading, $heading-props in $headings {
  h#{$heading} {
    @extend %heading;

    font-size: map-get($heading-props, size);
    font-weight: map-get($heading-props, weight);
    line-height: map-get($heading-props, line-height);
    margin-bottom: map-get($heading-props, offset);

    @if $heading >= 4 {
      & {
        font-family: $font-family-base;
      }
    }
  }
}

.saturn-heading {
  margin: 0 0 20px;

  &.heading {
    &--offset {
      &-large {
        margin-bottom: 50px;
      }

      &-short {
        margin-bottom: 10px;
      }
    }

    &--color {
      &-primary {
        color: $primary-color;
      }

      &-default {
        color: $gray90;
      }

      &-secondary {
        color: $white;
      }
    }

    &--align {
      &-end {
        text-align: end;
      }

      &-center {
        text-align: center;
      }
    }
  }
}
