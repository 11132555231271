@import '../../../styles/variables';

.card {
  transition: all 0.5s ease-in-out;
  background: transparent;

  &:not(:last-child) {
    margin-bottom: 4px;
  }
}

.draggableCard {
  cursor: grabbing;
  position: relative;

  &::before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 484.2 767.5'%3E%3Cpath d='M384 567c59-2 109 57 99 115-7 59-73 100-129 81-59-17-89-91-58-144 17-32 52-52 88-52zm-284 0c60-2 110 57 99 115-7 59-73 100-128 81-59-17-89-91-59-144 17-32 52-52 88-52Zm284-283c59-2 109 57 99 115-7 59-73 99-129 80a100 100 0 0 1 30-196zm-284 0c60-2 110 57 99 115-7 59-73 99-128 80-59-16-89-91-59-143 17-32 52-53 88-52ZM384 0c59-2 109 57 99 116-7 58-73 99-129 80-59-16-89-91-58-144 17-31 52-52 88-52ZM100 0c60-2 110 57 99 116-7 58-73 99-128 80-59-16-89-91-59-144C29 21 64 0 100 0Z'/%3E%3C/svg%3E");
    opacity: 0.5;
    display: block;
    position: absolute;
    left: 8px;
    top: 6px;
    width: 8px;
    height: 16px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  .sidebarListItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: default;
    min-height: 32px;
    background-color: $white;
    border: none;
    padding: 6px 8px 6px 26px;
    font-family: $font-family-base;
    line-height: 16px;

    a {
      color: $gray50;
      font-size: 12px;
      line-height: 16px;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .nestedWrapper {
    padding-left: 20px;
    margin-top: 0;
  }

  .notVisible {
    background: $gray10;
    border: none;

    a {
      color: #aab2ba;
      line-height: 1.4;
    }
  }

  .parentItem {
    display: flex;
  }
}
