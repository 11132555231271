@import '../../../styles/variables';

.toggleWrapper {
  display: flex;
  gap: 16px;
}

.toggleLabel {
  display: flex;
}

.label {
  font-weight: 700;
  font-size: 16px;
}

.dateTimeBlock {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  :global {
    .saturn-time-picker {
      .ant-picker-suffix {
        margin-right: 2px;

        & > span[role='img'] {
          font-size: 20px;
        }
      }

      .ant-picker-footer-extra {
        padding: 0;
      }
    }
  }
}

.displayCountdownBlock {
  margin-top: 16px;
}

.activeCampaignWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;

  .label {
    margin-bottom: 0;
  }

  .activeCampaignSwitch {
    margin-bottom: 0;
  }
}

.displayNowWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  span {
    font-weight: 600;
  }
}

.dateTimeBlockV2 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  :global {
    .saturn-time-picker {
      .ant-picker-suffix {
        margin-right: 2px;

        & > span[role='img'] {
          font-size: 20px;
        }
      }

      .ant-picker-footer-extra {
        padding: 0;
      }
    }
  }

  .dateTimeBlockItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }

  .datePicker {
    flex: 1;
  }
}

:global {
  .ant-picker-footer {
    position: relative;
  }
}

.footer {
  position: absolute;
  display: flex;
  gap: 16px;

  .extraPickerBtn {
    padding: 0;

    &:hover {
      background: transparent !important;
      opacity: 0.8;
    }

    span {
      color: $primary-color;
      font-size: 14px;
    }

    &.alignLeft {
      left: 8px;
    }
  }
}
