%ant-btn-common {
  font-family: $font-family-base;
  font-size: $font-size-body-base;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
  border-radius: $border-radius-base;
  height: $btn-height;
  padding: 4px 15px;

  &,
  &:hover,
  &:focus {
    box-shadow: none;
    text-decoration: none;
  }
}

%ant-btn-button {
  height: $btn-height;
  padding: $btn-padding-y $btn-padding-x;
  border-radius: $border-radius-base;

  @include transition(background-color 0.3s, border-color 0.3s, color 0.3s);

  border: 1px solid $primary-color;

  &.ant-btn-sm {
    height: $btn-height - 10;
    padding-left: 16px;
    padding-right: 16px;
  }

  @media screen and (width >= 320px) and (width <= 768px) {
    padding: $btn-padding-y;
  }
}

a.ant-btn {
  padding-top: 13px !important;
}

.ant-table-filter-dropdown {
  &-btns {
    .ant-btn {
      font-size: 14px;
      height: 24px;
      padding: 0 7px;
      border-radius: 4px;

      &-link {
        &::after {
          display: none;
        }
      }
    }
  }
}

.ant-btn {
  @extend %ant-btn-common;

  &#{&}-loading {
    &::before {
      display: none;
    }
  }

  &-primary {
    @extend %ant-btn-button;

    &,
    &:link,
    &:visited {
      color: $white;
      background-color: $primary-color;

      a {
        color: $white;
      }
    }

    &:not(:disabled, .ant-btn-disabled) {
      &:hover {
        background-color: $royal-blue-dark;
        border-color: $royal-blue-dark;
      }

      &:active,
      &:focus {
        background-color: $primary-color;
        border-color: $royal-blue-dark;
      }
    }

    &:disabled {
      pointer-events: none;
      color: $white;
      background-color: $disabled-blue;
      border-color: $disabled-blue;
    }
  }

  &-default {
    @extend %ant-btn-button;

    &,
    &:link,
    &:visited {
      color: $primary-color;
      background-color: $white;
      border-color: $primary-color;
    }

    &:not(:disabled, .ant-btn-disabled) {
      &:hover {
        color: $white;
        background-color: $royal-blue-dark;
        border-color: $royal-blue-dark;
      }

      &:active,
      &:focus {
        color: $royal-blue-dark;
        background-color: $white;
        border-color: $royal-blue-dark;
      }
    }

    &:disabled {
      pointer-events: none;
      background-color: $white;
      color: $disabled-blue;
      border-color: $disabled-blue;
    }
  }

  &-text {
    color: $black;

    @include transition(color 0.3s);

    .saturn-icon {
      @include transition(color 0.3s);
    }

    &:focus,
    &:hover {
      color: rgb(0 0 0 / 85%);
    }
  }

  &-link {
    display: inline-block;
    position: relative;
    font-size: 15px;
    height: 24px;
    padding: 0;
    transition: $animation-base;

    &,
    &:link,
    &:hover {
      color: $primary-color;
      opacity: 1;
      border: 0 none;
      background-color: transparent;
      text-decoration: none;
    }

    > .ant-btn-link-arrow {
      margin-left: 10px;

      svg {
        fill: $primary-color;
        transition: $animation-base fill;
      }
    }

    svg {
      path {
        @include transition(fill 0.3s);
      }
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -4px;
      left: 1px;
      width: 30px;
      height: 2px;
      border-radius: 2px;
      background-color: $primary-color;

      @include transition(background-color 0.3s, width 0.3s);
    }

    &:active,
    &:focus {
      color: $royal-blue-dark;

      svg {
        path {
          fill: $royal-blue-dark;
        }
      }
    }

    &:hover,
    &:active,
    &:focus {
      &::after {
        width: 100%;
      }
    }

    &:active,
    &:focus {
      > .ant-btn-link-arrow svg {
        fill: $royal-blue-dark;
      }

      &::after {
        background-color: $royal-blue-dark;
      }
    }

    &:disabled {
      pointer-events: none;
      color: $disabled-blue;
      background: none;

      > .ant-btn-link-arrow svg {
        fill: $disabled-blue;
      }

      &::after {
        background-color: $disabled-blue;
      }
    }
  }
}

.static-button-link {
  @extend %ant-btn-common;

  display: inline-flex;
  position: relative;
  font-size: 15px;
  height: 24px;
  padding: 0;
  transition: $animation-base;

  & > span {
    text-decoration: none;
  }

  &::after {
    content: '';
    display: none;
  }

  &,
  &:link,
  &:hover {
    color: $primary-color;
    opacity: 1;
    border: 0 none;
    background-color: transparent;
  }

  &:hover {
    & > span {
      text-decoration: underline;
    }
  }

  &:active,
  &:focus {
    color: $royal-blue-dark;

    & > span {
      text-decoration: underline;
    }

    svg {
      path {
        fill: $royal-blue-dark;
      }
    }
  }

  &:disabled {
    pointer-events: none;
    color: $disabled-blue;
    background: none;
  }
}

.saturn-tag {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 2px;
  border: 0;

  @include transition(background-color 0.3s, color 0.3s);

  &,
  &:link,
  &:visited {
    color: $primary-color;
    background-color: $light-blue;
  }

  &:hover,
  &:focus,
  &:active {
    color: $white;
    background-color: $primary-color;
  }
}

.ant-tag {
  font:
    normal 700 11px/24px Helvetica,
    Arial,
    sans-serif;
  background: $light-blue;
  padding: 0 10px;
  border-radius: 2px;
  color: $primary-color;
  text-transform: uppercase;
  border: none;
  white-space: normal;
  margin-bottom: 10px;

  &:hover {
    background-color: $primary-color;
  }

  a {
    color: inherit;

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }
}

.btn-circle {
  color: $white;
  padding: 0;
  box-shadow: $box-shadow;
  font-size: 24px;

  &.call {
    width: 87px;
    height: 87px;
    border-radius: 50%;
    background: var(--royal-blue-dark);
    position: relative;

    @media screen and (width >= 320px) and (width <= 768px) {
      background: var(--royal-blue-dark);
      width: 64px;
      height: 64px;
    }

    .callback-title {
      position: absolute;
      right: 65px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      border-radius: 4px;
      background: var(--al-mid-gray);
      box-shadow: 0 4px 4px 0 rgb(0 0 0 / 25%);
      z-index: -1;
      width: 0;
      padding: 14px 0;
      overflow: hidden;
      transition: all 0.3s;

      @media screen and (width >= 320px) and (width <= 768px) {
        background: rgba(#53565a, 0.8);
        display: none;
      }
    }

    .saturn-icon {
      color: var(--white);
      display: flex;
      align-items: center;

      @media screen and (width >= 320px) and (width <= 768px) {
        font-size: 40px !important;
      }
    }

    &:hover {
      box-shadow: 0 4px 4px 0 rgb(0 0 0 / 25%) !important;

      @media screen and (width >= 320px) and (width <= 768px) {
        background: rgba(#00175a, 0.9);
      }

      .callback-title {
        width: auto;
        padding: 14px 38px 14px 21px;
        transition: all 0.3s;

        @media screen and (width >= 320px) and (width <= 768px) {
          background: rgba(#53565a, 0.9);
        }
      }
    }
  }

  &.up {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: var(--al-mid-gray);
    color: var(--white);
    box-shadow: none;
    border: none;

    @media screen and (width >= 320px) and (width <= 768px) {
      background: rgba(#53565a, 0.8);
    }

    &:hover {
      @media screen and (width >= 320px) and (width <= 768px) {
        background: rgba(#53565a, 0.9);
      }
    }
  }
}

.ant-back-top {
  width: auto;
  height: auto;
  right: 40px;
  left: auto;
  bottom: 150px;
  z-index: 21;

  &.offsetFooter {
    @media screen and (width >= 320px) and (width <= 768px) {
      bottom: 50%;
      transform: translateY(50%);
    }
  }
}
