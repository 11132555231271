@import '../../../styles/variables';

.icons-gallery {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &__label {
    &:first-of-type {
      color: $gray50;
      font-size: 12px;
      margin-bottom: 3px;
      width: 100%;
    }
  }

  &__icon-thumbnail,
  &__icon {
    width: 75px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $alice-blue;
    border-radius: 4px;
  }

  &__icon-thumbnail {
    margin-right: 15px;
  }

  &__button {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: underline;

    &::after,
    span + span {
      display: none;
    }
  }
}

.modal {
  :global {
    .ant-modal-body {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      max-height: 400px;
      overflow-y: scroll;
    }
  }

  .modal-icons {
    &__icon {
      cursor: pointer;

      &--active {
        border: 3px solid $royal-blue-dark;
      }
    }
  }
}
