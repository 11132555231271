@import '../../../../styles/variables';

.label {
  font-weight: bold;
  font-size: 16px;
  margin: 0;
}

.toggleWrapper {
  display: flex;
  align-items: baseline;

  .switch {
    margin-left: 10px;
    margin-right: 10px;
    position: relative;
    top: -3px;
  }

  .tooltip {
    position: relative;
    top: 5px;
  }
}

.collapse {
  :global {
    .ant-collapse-item .ant-collapse-header {
      border-top: none !important;
      background-color: $gray10 !important;
      font-size: 16px;
      font-weight: bold;
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .saturn-content-block span {
      font-weight: bold;
    }
  }
}

.content {
  margin: 0 24px;
}
