@import '../../../../../../../saturn-uikit/src/styles/base/variables';
@import '../../../../../../../saturn-uikit/src/styles/base/transitions';

.wrapper {
  margin-bottom: 30px;

  .content {
    margin-top: 16px;
  }

  .collapsibleBlockHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .deleteBlockButton {
      position: relative;

      :global .ant-btn {
        border: none;
        color: $red;
        height: 30px;
        font-size: 11px;
        font-weight: bold;
        padding: 13px 16px;
        text-transform: uppercase;

        svg {
          fill: $red;
          font-size: 15px;
          margin-right: -3px;

          @include transition(fill 0.3s);
        }

        &:hover,
        &:active,
        &:focus {
          background-color: $red;
          color: $white;

          svg {
            fill: $white;
          }
        }
      }
    }
  }
}
