@import 'styles/variables';

.priceRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 24px;

  .priceItem,
  .priceSubtitleItem {
    flex: 1;
  }
}

.mostPopular {
  display: flex;
  align-items: baseline;
  gap: 16px;

  .label {
    font-weight: 700;
    font-size: 16px;
  }
}

.toggleWrapper {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: flex-start;
  background-color: $gray10;
  padding: 14px 20px;
  margin-bottom: 20px;

  p {
    margin-bottom: 0;
  }

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .switchItem {
    label {
      height: unset;
      margin-right: 10px;

      &::after {
        display: none;
      }
    }
  }
}

.label {
  font-weight: 700;
  font-size: 16px;
}

.paddedContent {
  padding: 0 24px;
}
