.ant-popover {
  .ant-btn {
    padding: 5px 15px;
    height: auto;
  }
}

.ant-dropdown-overlay {
  .ant-dropdown-menu {
    padding: 0;
  }

  .ant-dropdown-menu-item {
    padding: 0 !important;
  }
}
