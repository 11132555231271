@import '../../../styles/variables';

.modal {
  :global {
    .ant-modal-content {
      border-radius: 4px;
    }
  }
}

.title {
  font-weight: 700;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
}

.subtitle {
  text-align: center;
  font-size: 15px;
  line-height: 22px;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  row-gap: 17px;
}

.cancel span {
  color: $primary-color;
}
