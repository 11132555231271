@import '../../../saturn-uikit/dist/saturn-antd-theme.css';
@import '../../../saturn-uikit/src/index';
@import '../styles/variables';
@import '../styles/typo';

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body {
  font-weight: $font-weight-base;
  font-size: $font-size-body-small;
  line-height: $line-height-body-base;
  font-family: $font-family-base;
}

.ant-form-item + .saturn-icon {
  justify-content: center;
}

.ant-col,
.ant-row,
[class*=' ant-form'] {
  font-family: $font-family-base;
}

p {
  margin-bottom: 20px;
}

a {
  color: $primary-color;

  &:hover {
    color: $primary-color;
    opacity: 0.8;
  }
}

h1 {
  @include fluid-type($min-vw, $max-vw, 42px, 48px);
}

h2 {
  @include fluid-type($min-vw, $max-vw, 28px, 34px);
}

h3 {
  @include fluid-type($min-vw, $max-vw, 22px, 26px);
}

h4 {
  @include fluid-type($min-vw, $max-vw, 20px, 22px);
}

$headings: (
  1: (
    size: 48px,
    offset: 1em,
  ),
  2: (
    size: 34px,
    offset: 1em,
  ),
  3: (
    size: 26px,
    offset: 1em,
  ),
  4: (
    size: 22px,
    offset: 1em,
  ),
  5: (
    size: 16px,
    offset: 1em,
  ),
  6: (
    size: 15px,
    offset: 1rem,
  ),
);

%heading {
  font-family: $font-family-heading-base;
}

@each $heading, $heading-props in $headings {
  h#{$heading} {
    @extend %heading;

    font-size: map-get($heading-props, size);
    margin-bottom: map-get($heading-props, offset);
  }
}

.saturn-heading {
  margin: 0 0 20px;

  &.heading {
    &--offset {
      &-large {
        margin-bottom: 50px;
      }

      &-short {
        margin-bottom: 10px;
      }
    }

    &--color {
      &-primary {
        color: $primary-color;
      }

      &-default {
        color: $gray90;
      }

      &-secondary {
        color: $white;
      }
    }

    &--align {
      &-end {
        text-align: end;
      }

      &-center {
        text-align: center;
      }
    }
  }
}
