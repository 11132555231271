@import '../../../styles/variables';

.subTitle {
  color: $gray50;
  font-size: $font-size-body-light;
}

.name {
  padding-right: 15px;
  font-weight: bold;
  font-size: $font-size-body-base;
}
